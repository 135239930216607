<template>
  <div class="landingPage">
    <div v-if="user" class="row">
      <div class="col-12">
        <div class="card rounded-lg">
          <div class="card-body px-5">
            <div v-if="isLoading" class="text-center">
              <b-spinner />
            </div>
            <ValidationObserver
                v-else
                v-slot="{ handleSubmit, invalid, touched }"
                ref="letterForm"
            >
              <form
                  class="letter-form d-flex flex-column"
                  @submit.prevent="handleSubmit(saveLetter)"
              >
                <div class="row">
                  <div class="col">
                    <div class="d-flex align-items-center mb-3">
                      <div class="mr-3">Template Name</div>
                      <div class="mr-5">
                        <TextInput v-model="letter.name" name="Template Name" margin-bottom="0" rules="required" />
                      </div>
                      <div>
                        <button type="button" class="btn btn-primary btn-sm" @click="previewTemplate = true">Preview</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col max-w">
                    <LeadMergeFieldsGroup dropdown-size="sm" :show-keyword="true" :show-contact-link="true" :hide-title="true" :options="editorOptions" @selected="appendFieldMCE" />
                    <vue2-tinymce-editor :id="'editor-' + editorId" :ref="'editor-' + editorId" v-model="letter.content"></vue2-tinymce-editor>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right mt-3">
                    <b-button type="submit" variant="primary" size="sm" :disabled="isBusy || (invalid && touched)">
                      <b-spinner v-if="isBusy" small />
                      <span v-else>Save</span>
                    </b-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
            <b-modal v-model="previewTemplate" :title="letter.name + ' Letter Template Preview'" :hide-footer="true" size="lg">
              <embed v-if="letter.id !== ''" :src="pdfURL" width="100%" style="min-height: 500px;"  />
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {Vue2TinymceEditor} from "vue2-tinymce-editor";
import LeadMergeFieldsGroup from "@components/Agency/LeadMergeFieldsGroup.vue";
export default {
  components:{
    LeadMergeFieldsGroup,
    Vue2TinymceEditor
  },
  data() {
    return {
      user: {
        agency: {
          whitelabel: {},
          landing_page: {}
        },
      },
      previewTemplate:false,
      isBusy:false,
      isLoading:false,
      letter:{
        name:'',
        content:'',
        id:''
      },
      editorId:new Date().getTime(),
      editorOptions:{
        menubar:true,
        plugins: 'advlist autolink charmap code codesample directionality emoticons ' +
            'fullscreen help hr image imagetools insertdatetime link lists ' +
            'media nonbreaking pagebreak paste preview print save searchreplace ' +
            'table template textpattern toc visualblocks visualchars wordcount',
        toolbar: 'fontselect fontsizeselect formatselect | bold italic underline strikethrough forecolor backcolor | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link table removeformat code'
      }
    }
  },
  computed: {
    pdfURL(){
      return (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API_LOCAL) + 'api/v1/agencies/stream-letter/' + this.$route.params.id;
    }
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
    this.getLetter();
  },
  methods: {
    appendFieldMCE(field){
      if(field === '[review_link]'){
        field = `<a href="${field}">Review Us</a>`;
      }
      const editor = this.$refs['editor-' + this.editorId];
      if (editor) {
        // Insert text at the current cursor position
        editor.editor.selection.setContent(field);
        this.$set(this.letter,'content',editor.editor.getContent())
      }
    },
    saveLetter(){
      this.isBusy = true;
      this.$store
          .dispatch('leads/saveLetter',this.letter)
          .then(() => {
            this.isBusy = false;
            Vue.$toast.open({
              message: 'Letter Saved',
              type: 'success',
              duration: 5000,
            })
          })
          .catch((err) => {
            console.log(err);
            this.isBusy = false;
          })
    },
    getLetter(){
      this.isLoading = true;
      this.$store
          .dispatch('leads/getLetter',this.$route.params.id)
          .then((resp) => {
            this.letter = resp.data;
            this.isLoading = false;
          })
          .catch((err) => {
            console.log(err);
          })
    },
  },
}
</script>
<style lang="scss">
  .tox-tinymce{
    min-height: 60vh;
  }
</style>
